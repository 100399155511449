import React, { useEffect, useState } from "react";
import styles from "./userSessionsAnalytics.module.css";
import commonStyles from "../../pages/analytics.module.css";
import VTPTable from "../base/vtpTable";
import VTPSortableTableHeader, {
  SortingState,
  useSortingStateMachine,
} from "../base/vtpSortableTableHeader";
import {
  Course,
  GetUserRole,
  SessionSummaryAnalyticsViewModel,
  User,
} from "../../lib/apiModels";
import { useEndpointPagination } from "../../hooks/usePagination";
import { useVTPCloud } from "../../context/vtpCloud-context";
import PageSection from "../base/pageSection";
import { ReactComponent as PersonIcon } from "../../assets/navigation/person.svg";
import CommonUtilities from "../../lib/common";
import { defaultDateRange, formatSessionLength } from "../../pages/analytics";
import TablePagination from "../base/table/tablePagination";
import { useAppContext } from "../../context/app-context";
import CourseAnalyticsButton from "./courseAnalyticsButton";
import VTPStyles from "../../styles/vtpStyles";
import VTPDateRangePicker from "../base/vtpDateRangePicker";
import { useTranslation } from "react-i18next";

enum UserSessionAnalyticsSortingKey {
  SessionStart = "sessionStart",
  SessionName = "sessionName",
  Course = "course",
  Duration = "duration",
}

const CourseSessionAnalytics = (props: { user: User; courses: Course[] }) => {
  // Component dependencies.
  const { t } = useTranslation();
  const { getSessionListAnalyticsView, getSessionSummaryView } = useVTPCloud();
  const { getSelectedTenant } = useAppContext();
  const [dateRange, setDateRange] = useState(defaultDateRange());

  // State handling
  const [sessionSummary, setSessionSummary] =
    useState<SessionSummaryAnalyticsViewModel>();

  // Paging and sorting
  const sortingStateMachine = useSortingStateMachine(
    UserSessionAnalyticsSortingKey.SessionStart,
    SortingState.Desc
  );

  const paging = useEndpointPagination(getSessionListAnalyticsView, 6, {
    sort: `${sortingStateMachine.state.value}:${sortingStateMachine.state.sortingState}`,
    userId: props.user.id,
    from: dateRange.from.toISOString(),
    to: dateRange.to.toISOString(),
  });

  useEffect(() => {
    getSessionSummaryView({
      userId: props.user.id,
      from: dateRange.from.toISOString(),
      to: dateRange.to.toISOString(),
    })
      .then((res) => setSessionSummary(res))
      .catch(() =>
        console.log(
          `Failed to load session summary for user (${props.user.id})`
        )
      );
  }, [dateRange]);

  // Sorting
  useEffect(() => {
    const sortingParam = `${sortingStateMachine.state.value}:${sortingStateMachine.state.sortingState}`;
    if (paging.options.sort !== sortingParam) {
      paging.setOptions({
        ...paging.options,
        sort: sortingParam,
      });
    }
  }, [sortingStateMachine.state]);

  // Date range changes
  useEffect(() => {
    if (
      paging.options.from !== dateRange.from.toISOString() ||
      paging.options.to !== dateRange.to.toISOString()
    ) {
      paging.setOptions({
        ...paging.options,
        from: dateRange.from.toISOString(),
        to: dateRange.to.toISOString(),
      });
    }
  }, [dateRange]);

  const columns = (): React.ReactNode[] => {
    return [
      <VTPSortableTableHeader
        key={UserSessionAnalyticsSortingKey.SessionStart}
        text={t("analyticsPage.tableColumns.login")}
        value={UserSessionAnalyticsSortingKey.SessionStart}
        stateMachine={sortingStateMachine}
      />,
      <VTPSortableTableHeader
        key={UserSessionAnalyticsSortingKey.SessionName}
        text={t("analyticsPage.tableColumns.session")}
        value={UserSessionAnalyticsSortingKey.SessionName}
        stateMachine={sortingStateMachine}
      />,
      <VTPSortableTableHeader
        key={UserSessionAnalyticsSortingKey.Course}
        text={t("analyticsPage.tableColumns.course")}
        value={UserSessionAnalyticsSortingKey.Course}
        stateMachine={sortingStateMachine}
      />,
      <VTPSortableTableHeader
        key={UserSessionAnalyticsSortingKey.Duration}
        text={t("analyticsPage.tableColumns.duration")}
        value={UserSessionAnalyticsSortingKey.Duration}
        stateMachine={sortingStateMachine}
      />,
    ];
  };

  const rows = (): React.ReactNode[][] => {
    if (!paging.pageData) {
      return [];
    }

    return paging.pageData?.map((data) => {
      const course = props.courses.find((c) => c.courseId === data.courseId);
      return [
        CommonUtilities.FormatDate(data.sessionStart),
        data.sessionName,
        course ? (
          <CourseAnalyticsButton key={course.courseId} course={course} />
        ) : null,
        formatSessionLength(data.duration),
      ];
    });
  };

  return (
    <PageSection
      header={{
        icon: <PersonIcon />,
        text: props.user.email,
        appendHeader: (
          <div className={styles.userSessionDetails}>
            <div>
              <span className={styles.boldHeader}>{t("analyticsPage.userSessions.role")}</span>
              <span className={VTPStyles.Typography.Body.Medium}>
                {GetUserRole(
                  props.user.userTenantRoles.find(
                    (r) => r.tenantId === getSelectedTenant?.id
                  )?.role,
                  t
                )}
              </span>
            </div>
            <div>
              <span className={styles.boldHeader}>{t("analyticsPage.userSessions.totalTime")}</span>
              <span className={VTPStyles.Typography.Body.Medium}>
                {formatSessionLength(sessionSummary?.totalTime ?? 0)}
              </span>
            </div>
          </div>
        ),
      }}
      style={{ backgroundColor: "#FFFFFF" }}
    >
      <div className={styles.userName}>{props.user.fullName}</div>
      <div className={commonStyles.datePickerGroup}>
        <div className={VTPStyles.Typography.Headers.H2SubheaderSmall}>
          {t("analyticsPage.userSessions.joinedSessions")}
        </div>
        <VTPDateRangePicker
          onSelected={(from, to) => setDateRange({ from: from, to: to })}
          defaultValue={dateRange}
        />
      </div>
      <div className={styles.tableContainer}>
        <VTPTable columns={columns()} rows={rows()} />
        <div className={styles.paging}>
          <TablePagination
            currentPage={paging.pagingInfo.CurrentPage}
            totalPages={paging.pagingInfo.TotalPages}
            nextPage={paging.nextPage}
            previousPage={paging.previousPage}
          />
        </div>
      </div>
    </PageSection>
  );
};

export default CourseSessionAnalytics;
